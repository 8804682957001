import { Formik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import arrow_left_gray from "../../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";
import AttributeFormGroup from "../../auth/register/parts/AttributeFormGroup";
import { putAttributes } from "../../../api/api";
import { useMutation } from "react-query";
import NoChildrenPopup from "../../components/NoChildrenPopUp";

export default function MissingSegments({ missingSegments, prevStep, nextStep, number }) {
    const { token } = useParams();
    const { email } = useParams();

    const [childrenAmount, setChildrenAmount] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);

    const updatedChildrenAmountInputs = (attribute, option, setFieldValue) => {
        let noChildren = option.label === "0" ? true : false;
        if (!noChildren) {
            setFieldValue(`attributes[${attribute.key}]`, option.value);
            let amount = option.label === "Meer dan 5" ? 6 : parseInt(option.label);
            setFieldValue("attributes[dob_children]", new Array(amount).fill(""));
            setChildrenAmount(amount);
        }

        setPopupOpen(noChildren);
    };

    const attributeMutation = useMutation((values) => putAttributes(values), {
        onSuccess: (data) => {
            console.log("wowsiers", data);
            nextStep(number + 1);
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const onSubmit = (values) => {
        console.log("submitting", values);
        attributeMutation.mutate(values);
        // nextStep();
    };

    return (
        <div>
            <Formik
                initialValues={{
                    token: token,
                    email: email,
                    attributes: missingSegments
                        .map((attribute) => {
                            if (attribute.key === "children_amount") {
                                return { [attribute.key]: attribute.options.find((o) => o.title === "1")?.id ?? "" };
                            } else if (attribute.key === "dob_children") {
                                return { [attribute.key]: [] };
                            } else if (attribute.type === "checkbox") {
                                return { [attribute.key]: false };
                            } else {
                                return { [attribute.key]: "" };
                            }
                        })
                        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, handleSubmit, setFieldValue }) => (
                    <form className="content" onSubmit={handleSubmit}>
                        <h1 className="subtitle">Jouw bijdrage aan onderwijs telt!</h1>
                        <p>
                            We missen nog een paar details om je te koppelen aan relevante onderzoeken. Vul de ontbrekende gegevens in en help ons het
                            onderwijs te verbeteren!
                        </p>
                        {missingSegments.map((attribute, index) => (
                            <AttributeFormGroup
                                key={index}
                                sleutel={index}
                                attribute={attribute}
                                setFieldValue={setFieldValue}
                                value={["children_amount", "dob_children"].includes(attribute.key) ? childrenAmount + "" : undefined}
                                errors={errors}
                                onChangeCallback={(attribute, option) => updatedChildrenAmountInputs(attribute, option, setFieldValue)}
                            />
                        ))}

                        <div className="buttons_container mt-2">
                            <button className="btn kingkong btn-back" type="button" onClick={() => prevStep()}>
                                <img src={arrow_left_gray} alt="arrow" />
                                <span style={{ paddingLeft: "10px" }}>Terug</span>
                            </button>
                            <button type="submit" className="btn btn-primary kingkong">
                                <span>Opslaan</span>
                                <img src={arrow_right_white} alt="arrow" />
                            </button>
                        </div>
                    </form>
                )}
            </Formik>

            <NoChildrenPopup open={popupOpen} setOpen={setPopupOpen} withUnsubscribe={true}></NoChildrenPopup>
        </div>
    );
}
